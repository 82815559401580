


import { Component } from 'vue-property-decorator';
import Auth from '@/Auth.vue';

@Component
export default class LoginHandlerPage extends Auth {
    public mounted() {
        // from auth0 login -> then Auth0Mixin
        this.redirectHandler();
    }

    // ダイアログボックスからホストページに情報を送信する処理
    // 参照：https://docs.microsoft.com/ja-jp/office/dev/add-ins/develop/dialog-api-in-office-add-ins
    private redirectHandler(): void {
        const messageObject: {messageType: string} = {messageType: 'CloseDialog'};
        const jsonMessage: string = JSON.stringify(messageObject);
        Office.context.ui.messageParent(jsonMessage);
    }
}
