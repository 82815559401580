var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.checkRequiredFlg === _vm.article.check_required_flg
    ? _c(
        "section",
        {
          staticClass: "cardContainer",
          class: _vm.isGrayOut(_vm.articleId, _vm.removeArticleIds)
            ? "-isGrayOut"
            : 0
        },
        [
          _c("div", { staticClass: "cardWrapper" }, [
            _c("div", { staticClass: "boxWrapper" }, [
              _c(
                "div",
                {
                  staticClass: "cardTitleWrapper",
                  class: { "-margin": _vm.isShowContext !== _vm.articleId }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "subWrapper",
                      on: {
                        click: function($event) {
                          return _vm.showContext(_vm.articleId)
                        }
                      }
                    },
                    [
                      _c("h2", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.article.article_title))
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "titleArrow" }, [
                        _c("img", {
                          class: {
                            "-none": _vm.isShowContext !== _vm.articleId
                          },
                          attrs: {
                            src: require("../../../assets/blueArrow_top.png")
                          }
                        }),
                        _vm._v(" "),
                        _c("img", {
                          class: {
                            "-none": _vm.isShowContext === _vm.articleId
                          },
                          attrs: {
                            src: require("../../../assets/blueArrow_bottom.png")
                          }
                        })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.article.memo,
                          expression: "article.memo"
                        }
                      ],
                      staticClass: "text",
                      on: {
                        click: function($event) {
                          return _vm.toMemo(_vm.article)
                        }
                      }
                    },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("h3", { staticClass: "memoText" }, [_vm._v("解説")])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowContext === _vm.articleId,
                      expression: "isShowContext === articleId"
                    }
                  ],
                  staticClass: "articleContentWrapper"
                },
                [
                  _c("p", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.article.article_body))
                  ])
                ]
              ),
              _vm._v(" "),
              !(_vm.article.one_phrase_comment === "")
                ? _c(
                    "div",
                    {
                      staticClass: "onePhraseCommentWrapper",
                      class: { "-margin": _vm.cardStyleMargin === true }
                    },
                    [
                      _c("p", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.article.one_phrase_comment))
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowContext === _vm.articleId,
                      expression: "isShowContext === articleId"
                    }
                  ],
                  staticClass: "moreTextWrapper"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text",
                      on: {
                        click: function($event) {
                          return _vm.toSeeMore(_vm.article)
                        }
                      }
                    },
                    [_vm._v("条文例をもっと見る"), _vm._m(1)]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cardBottom" }, [
              _c("ul", { staticClass: "cardMenuList" }, [
                _c(
                  "li",
                  {
                    staticClass: "cardMenuListItem",
                    on: {
                      click: function($event) {
                        return _vm.changeParentRemoveArticleIds(
                          _vm.articleId,
                          _vm.article.index
                        )
                      }
                    }
                  },
                  [
                    _vm._m(2),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.childCardMessage) +
                        "\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.article.line_no > 0,
                        expression: "article.line_no > 0"
                      }
                    ],
                    staticClass: "cardMenuListItem",
                    on: {
                      click: function($event) {
                        return _vm.goToParagraph(_vm.article.index, "doNothing")
                      }
                    }
                  },
                  [_vm._m(3), _vm._v("フォーカス\n                ")]
                )
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "memoImage" }, [
      _c("img", {
        attrs: { src: require("../../../assets/memo.png"), alt: "解説" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "moreTextArrow" }, [
      _c("img", {
        attrs: { src: require("../../../assets/blueArrow_right.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("../../../assets/solution.png"), alt: "solution" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("../../../assets/focus.png"), alt: "solution" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }