






























import { Component } from 'vue-property-decorator';
import Loading from '@molecules/Loading.vue';
import Header from '@organisms/Header.vue';
import LogOutButton from '@molecules/LogOutButton.vue';
import Auth from '@/Auth.vue';
import constants from '@/constants';

@Component({
    components: {
        Loading,
        Header,
        LogOutButton,
    },
})

export default class MoreInTemplatesMemo extends Auth {
    private article = this.$store.getters.articleForMemo;

    private templateSearchType?: number = this.$store.getters.templateSearchType;

    public mounted() {
        // スクロール位置を保持するための記述です
        const referer = this.$route.query.referer;

        // 検索結果からきたときのみ、スクロール位置を一番上に戻しています
        if (referer === constants.REFERER_SEARCH_RESULT) {
            window.scroll(0, 0);
        }
    }

    // ひな型の種類を変更する
    private changeTemplateSearchType(templateSearchType: number): void {
        this.$store.commit('templateSearchType', templateSearchType);
    }

    // リンク元へ戻る処理
    private backWithQuery(): void {
        const referer: string|Array<string|null> = this.$route.query.referer;

        // 検索結果ページからきたときの処理
        if (referer === constants.REFERER_SEARCH_RESULT) {
            this.$router.push({
                name: constants.REFERER_SEARCH_RESULT,
                query: {
                    referer: constants.REFERER_TEMPLATE_MEMO,
                },
            });
        } else {
            // 検索結果ページ以外からきたときの処理
            this.$router.push({
                name: constants.REFERER_SEE_MORE,
                query: {
                    referer: constants.REFERER_TEMPLATE_MEMO,
                    contract_id: this.$route.query.contract_id,
                    article_id: this.$route.query.article_id,
                    option_articles: this.$route.query.option_articles,
                    related_documents: this.$route.query.related_documents,
                },
            });
        }
    }
}
