// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/error_human.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Lato);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".errorImage[data-v-dca35916]{width:20rem;height:7.9rem;margin:0 auto 2rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:contain}.errorTitleWrapper[data-v-dca35916]{display:flex;justify-content:center;align-items:center;margin:0 auto 1.8rem}.errorTitle[data-v-dca35916]{color:#123465;line-height:3rem;font-size:2rem;font-weight:bold;text-align:center}.errorTitle.-searchInTemplate[data-v-dca35916]{max-width:25rem}.errorTitleImage[data-v-dca35916]{vertical-align:middle}.errorTitleImage img[data-v-dca35916]{max-width:100%}.errorText[data-v-dca35916]{font-size:1.2rem;text-align:center}\n", ""]);
// Exports
module.exports = exports;
