export default Object.freeze({
    TEMPLATE_SEARCH_TYPE_CUSTOMER: 0,
    TEMPLATE_SEARCH_TYPE_GVATECH: 1,
    REFERER_SEARCH: 'search-in-templates-page',
    REFERER_SEARCH_RESULT: 'search-result-in-templates-page',
    REFERER_SEE_MORE: 'see-more-page',
    REFERER_TEMPLATE_MEMO: 'template-memo-page',
    COLOR_GRAY: '#999999',
    DECIMAL_SYSTEM: 10,
});
