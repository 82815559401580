































































import { Component, Prop } from 'vue-property-decorator';
import ErrorBox from '@/components/molecules/ErrorBox.vue';
import ContractTypeForm from '@/components/molecules/ContractTypeForm.vue';
import BoxLoading from '@/components/molecules/BoxLoading.vue';
import PositionForm from '@/components/molecules/PositionForm.vue';
import FirstSearchCondition from '@/components/molecules/FirstSearchCondition.vue';
import SecondSearchConditions from '@/components/molecules/SecondSearchConditions.vue';
import TargetContracts from '@/components/molecules/TargetContracts.vue';
import axios from 'axios';
import constants from '@/constants';
import local from '@/constants/local';
import {IPositions, ISecondSearchCondition } from '@/interfaces';
import * as Config from '@/config';
import { Mixins } from 'vue-mixin-decorator';
import SearchTypeMixin from '@/mixins/SearchTypeMixin.vue';

@Component({
    components: {
        ErrorBox,
        ContractTypeForm,
        BoxLoading,
        PositionForm,
        FirstSearchCondition,
        SecondSearchConditions,
        TargetContracts,
    },
})
export default class SearchForm extends Mixins<SearchTypeMixin>(SearchTypeMixin) {
    private positions: IPositions = {positions: [], first_search_conditions: []};
    private isFinishedSearchCondition: boolean = false;
    private secondSearchConditions: ISecondSearchCondition[] = [];
    private selectedContractType: number = 0;
    private selectedPosition: number = 0;
    private selectedFirstSearchCondition: number = 0;
    private selectedSecondSearchConditions: number[] = [];
    private targetContractId: number = 0;
    private isEmpty: boolean = false;

    @Prop({default: () => []})
    private readonly contractTypes!: any[];

    @Prop(Object)
    private readonly headers!: object;

    private templateSearchType: number = this.$store.getters.templateSearchType;

    public mounted() {
        // 契約書ファイルが空（＝なにも記載がない）か否かチェックする
        this.isEmptyFile();
    }

    // contractTypesを選択したとき、positionsを取得する
    private async selectContractType(contractTypeId: number): void {
        this.selectedContractType = contractTypeId;
        const url: string = this.getUrlForPositions(this.templateSearchType, this.selectedContractType);

        if (url === '') {
            return;
        }

        // yarn start:stand-alone で起動したとき
        if (Config.IS_STANDALONE) {
            this.positions = local.DATA_POSITIONS;
        } else {
            try {
                const {data}: any = await axios.get(url, { headers: this.headers});
                this.positions = data;
            } catch (err) {
                throw new Error(err);
            }
        }
        this.resetOnSelectContractType();
    }

    // contractTypesを変更したとき、既存の値（selectedPosition, selectedFirstSearchCondition, selectedSecondSearchConditions , targetContractId）をリセットする
    private resetOnSelectContractType(): void {
        this.selectedPosition = 0;
        this.selectedFirstSearchCondition = 0;
        this.selectedSecondSearchConditions = [];
        this.targetContractId = 0;
    }

    // positionsの情報を取得して、APIのURLを返す
    private getUrlForPositions(templateSearchType: number, contractTypeId: number): string {
        if (contractTypeId <= 0) {
            return '';
        }

        const searchType: string = this.getSearchTypes(templateSearchType);
        const url: string = `https://${Config.API_DOMAIN}/api/v1.0/${searchType}/contract_types/${contractTypeId}/positions`;
        return url;
    }

    // positionsを選択したとき、secondSearchConditionsをセットする
    private async selectPosition(position: number): Promise<void> {
        this.selectedPosition = position;
        await this.setSecondSearchConditions();
    }

    // firstSearchConditionを選択したとき、secondSearchConditionsの情報を取得して、セットする
    private async selectFirstSearchCondition(firstSearchConditionId: number): Promise<void> {
        this.selectedFirstSearchCondition = firstSearchConditionId;
        await this.setSecondSearchConditions();
    }

    // secondSearchConditionsの情報を取得して、セットする
    private async setSecondSearchConditions(): Promise<void> {
        const url: string = this.getUrlForSecondSearchConditions(this.templateSearchType, this.selectedContractType, this.selectedPosition, this.selectedFirstSearchCondition);

        if (url === '') {
            return;
        }

        // yarn start:stand-alone 起動時
        if (Config.IS_STANDALONE) {
            this.secondSearchConditions = local.DATA_SECOND_SEARCH_CONDITIONS;
            this.isFinishedSearchCondition = true;
        } else {
            try {
                const {data}: any = await axios.get(url, { headers: this.headers} );
                this.secondSearchConditions = data;
                this.isFinishedSearchCondition = true;
            } catch (err) {
                throw new Error(err);
            }
        }
        // secondSearchConditionsの情報が取得されたとき、selectedSecondSearchConditionsとtargetContractIdの値をリセットする
        this.resetOnSelectPositionOrFirstSearchCondition();
    }

    // secondSearchConditionsの情報が取得されたとき、selectedSecondSearchConditionsとtargetContractIdの値をリセットする
    private resetOnSelectPositionOrFirstSearchCondition(): void {
        this.selectedSecondSearchConditions = [];
        this.targetContractId = 0;
    }

    // secondSearchConditionsを選択したとき、selectedSecondSearchConditionsに値を入れる
    private checkedSecondSearchConditions(num: number[]): void {
        this.selectedSecondSearchConditions = num;
        this.targetContractId = 0;
    }

    // secondSearchConditionsのURLを取得する
    private getUrlForSecondSearchConditions(templateSearchType: number, contractTypeId: number, positionId: number, firstSearchConditionId: number): string {
        if (positionId <= 0 || firstSearchConditionId <= 0) {
            return '';
        }
        const searchType: string = this.getSearchTypes(templateSearchType);
        const url: string = `https://${Config.API_DOMAIN}/api/v1.0/${searchType}/contract_types/${contractTypeId}/positions/${positionId}/first_search_conditions/${firstSearchConditionId}/second_search_conditions`;
        return url;
    }

    // targetContractIdが選択された時、ウィンドウの一番下へスクロールするようにする
    private async selectTargetContractId(selectedTargetContractIdNumber: number) {
        this.targetContractId = selectedTargetContractIdNumber;
        await this.scrollToBottom();
    }

    // 「比較する契約書ファイル」を選んだら、ウィンドウの一番下へスクロールするようにする
    private scrollToBottom(): void {
        const d: HTMLElement = document.documentElement;
        const y: number = d.scrollHeight - d.clientHeight;

        window.scroll(0, y);
    }

    // targetContractsのデータを取得する
    get targetContracts() {
        if (this.secondSearchConditions.length > 0 && this.selectedSecondSearchConditions.length > 0) {
            const targetContracts: object[] = [];
            this.secondSearchConditions.map((s) => {
                if (this.selectedSecondSearchConditions.includes(s.id)) {
                    s.contract_titles.map((item) => {
                        targetContracts.push(item);
                    });
                }
            });
            return targetContracts;
        } else {
            return [];
        }
    }

    // 「この契約書と比較する」ボタンを押したときの処理
    private compareContract(): void {
        // search result 表示用
        this.$store.commit('targetContractId', this.targetContractId);
        this.$store.commit('targetContracts', this.targetContracts);

        this.$router.push({
            name: constants.REFERER_SEARCH_RESULT,
            query: { referer: constants.REFERER_SEARCH },
        });
    }

    // 契約書ファイル(.docx)が空（＝なにも記載がない）か否かチェックする
    private isEmptyFile(): void {
        // yarn testの時、何も返さないようにしてテスト(jest)を通すようにする
        if (Config.IS_TEST) {
            return;
        }
        // 開かれているファイル(.docx)が空かどうかを調べる
        Word.run((context: Word.RequestContext) => {
            const body = context.document.body;
            context.load(body);

            return context.sync().then(() => {
                if (this.hasEmptyFlag(body.text) === true) {
                    this.isEmpty = true;
                }
            });
        })
            .catch((error) => {
                /* tslint:disable:no-console */
                console.error(error);
            });
    }

    // 契約書ファイル(.docx)が開かれている（＝何か記載がある）場合、trueを返すようにする
    private hasEmptyFlag(text: string): boolean {
        if (text.trim() === '') {
            return true;
        }
        return false;
    }
}
