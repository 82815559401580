












import { Component } from 'vue-property-decorator';
import App from '@/App.vue';

@Component
export default class LogOutButton extends App {
    private logout(): void {
        this.logoutFromAuth0();
    }

    private logoutFromAuth0(): void {
        // https://github.com/auth0/auth0-spa-js/blob/902062723784d29b1bbeac9ba634071a51e38c4b/src/Auth0Client.ts#L425
        // 以下は、auth0-spa-js側で window.locationでauth0上のurlを指定しているため、ブラウザが開く、ので使わない。
        // const auth0 = this.$root.$data.auth0;
        // await auth0.logout();

        // auth0-spa-jsではcookieを削除しているだけなので、自前で記載する。
        this.$cookies.remove('auth0.is.authenticated');
        this.$router.push({name: 'login-page'});
    }
}
