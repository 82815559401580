






















import { Component, Prop, Emit } from 'vue-property-decorator';
import {IPositions } from '@/interfaces';
import App from '@/App.vue';

@Component
export default class FirstSearchCondition extends App {
    @Prop({default: []})
    private readonly positions!: IPositions[];

    @Prop({default: 0})
    private readonly selectedFirstSearchCondition!: number;

    private childSelectedFirstSearchCondition: number = this.selectedFirstSearchCondition;

    @Emit()
    private selectFirstSearchCondition(firstSearchConditionId: number): void {
        this.childSelectedFirstSearchCondition = firstSearchConditionId;
    }
}
