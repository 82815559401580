


import {Vue} from 'vue-property-decorator';
import {Mixin} from 'vue-mixin-decorator';
import constants from '@/constants';
import * as Config from '@/config';

@Mixin
export default class SearchTypeMixin extends Vue {

    // getSearchTypes return "customer" or "gvatech".
    private getSearchTypes(templateSearchType?: number): string {
        if (templateSearchType === constants.TEMPLATE_SEARCH_TYPE_CUSTOMER) {
            return 'customer';
        } else {
            return 'gvatech';
        }
    }

}
