
















import { Component, Prop, Emit } from 'vue-property-decorator';
import App from '@/App.vue';

@Component
export default class ContractTypeForm extends App {
    @Prop({default: []})
    private readonly contractTypes!: any[];

    @Prop({default: 0})
    private readonly selectedContractType!: number;

    private childSelectedContractType: number = this.selectedContractType;

    @Emit()
    private selectContractType(contractTypeId: number): void {
        this.childSelectedContractType = contractTypeId;
    }
}
