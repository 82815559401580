





















import { Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { ISecondSearchCondition } from '@/interfaces';
import App from '@/App.vue';

@Component
export default class SecondSearchConditions extends App {

    @Prop({default: []})
    private readonly secondSearchConditions!: ISecondSearchCondition[];

    @Prop({default: []})
    private readonly selectedSecondSearchConditions!: number[];

    private childSelectedSecondSearchConditions: number[] = this.selectedSecondSearchConditions;

    @Watch('selectedSecondSearchConditions')
    private checkedSecondSearchConditions(newValue: any[], oldValue: any[]): void {
        this.checkedSecondSearchConditions();
    }

    @Emit()
    private checkedSecondSearchConditions(checkedSecondSearchConditionsNumber: number[]): void {
        this.childSelectedSecondSearchConditions = checkedSecondSearchConditionsNumber;
    }
}
