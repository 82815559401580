



import { Component } from 'vue-property-decorator';
import {IS_STANDALONE} from '@/config';
import {LOGIN_DIALOG_DOMAIN} from '@/config';
import { Mixins } from 'vue-mixin-decorator';
import SearchTypeMixin from '@/mixins/SearchTypeMixin.vue';

@Component
export default class Auth extends Mixins<SearchTypeMixin>(SearchTypeMixin) {

    public async mounted() {
        const currentPageName = this.$route.name || '';
        const isAuthenticated = await this.isAuthenticated();

        if (this.needAuthenticated(currentPageName)) {
            if (isAuthenticated === false) {
                // 認証されていなければtopページ
                this.$router.push({name: 'login-page'});
            } else {
                const user = await this.getUser();
                this.bootIntercom(user);
            }
        }
    }

    private bootIntercom(user: object): void {
        if (!IS_STANDALONE) {
            this.$intercom.boot({
                user_id: user.sub,
                email: user.email,
            });
        }
    }

    private needAuthenticated(currentPageName: string): boolean {
        const exceptionPageName = [
            'login-page',
            'login-dialog',
            'login-handler',
            'search-in-templates-page',
        ];
        if (exceptionPageName.includes(currentPageName)) {
            return false;
        }
        return true;
    }

    private async isAuthenticated() {
        if (IS_STANDALONE) {
            return true;
        } else {
            const auth0 = this.$root.$data.auth0;
            const isAuthenticated = await auth0.isAuthenticated();
            return isAuthenticated;
        }
    }

    private async getUser() {
        if (IS_STANDALONE) {
            const user = {
                sub: '1|dummy_identifier',
                email: 'dummy@gvatech.co.jp',
            };
            return user;
        } else {
            const auth0 = this.$root.$data.auth0;
            const user = await auth0.getUser();
            return user;
        }
    }

    private async getAccessToken() {
        if (IS_STANDALONE) {
            return 'dummy token';
        } else {
            const auth0 = this.$root.$data.auth0;
            const accessToken = await auth0.getTokenSilently();
            return accessToken;
        }
    }

    private setAccessTokenToAuthorizationHeader(headers: any, accessToken: string): object {
        headers.Authorization = `Bearer ${accessToken}`;
        return headers;
    }

    private loginToAuth0() {
        /* tslint:disable:no-console */
        Office.context.ui.displayDialogAsync(`https://${LOGIN_DIALOG_DOMAIN}/index.html#login_dialog`, { width: 30, height: 80 },
            (asyncResult) => {
                if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                    console.error(Office.AsyncResultStatus.Failed);
                } else {
                    const dialog = asyncResult.value;
                    dialog.addEventHandler(
                        Office.EventType.DialogMessageReceived,
                        (arg: any): void => {
                            dialog.close();
                            if (arg.error) {
                                console.error(arg.error);
                            } else {
                                const messageFromDialog = JSON.parse(arg.message);
                                if (messageFromDialog.messageType === 'CloseDialog') {
                                    this.$router.push({name: 'search-in-templates-page'});
                                } else {
                                    console.error(`messageType is different. : ${messageFromDialog.messageType}`);
                                }
                            }
                        },
                    );
                }
            },
        );
    }

}
