import Vue from 'vue';
import VueRouter from 'vue-router';

import LoginPage from '@pages/LoginPage.vue';
import LoginDialogPage from '@pages/LoginDialogPage.vue';
import LoginHandlerPage from '@pages/LoginHandlerPage.vue';

import SearchInTemplatesPage from '@pages/SearchInTemplatesPage.vue';

import SearchResultInTemplatesPage from '@pages/SearchResultInTemplatesPage.vue';
import SeeMorePage from '@pages/SeeMorePage.vue';
import TemplateMemoPage from '@pages/TemplateMemoPage.vue';

Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        {path: '/', name: 'login-page', component: LoginPage},
        {path: '/login_dialog', name: 'login-dialog', component: LoginDialogPage},
        {path: '/login_handler', name: 'login-handler', component: LoginHandlerPage},

        {path: '/search_in_templates', name: 'search-in-templates-page', component: SearchInTemplatesPage},

        {path: '/search_result_in_templates', name: 'search-result-in-templates-page', component: SearchResultInTemplatesPage},
        {path: '/see_more', name: 'see-more-page', component: SeeMorePage},
        {path: '/template_memo', name: 'template-memo-page', component: TemplateMemoPage},
    ],
});

export default router;
