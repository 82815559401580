


import App from '@/App.vue';
import {Mixin} from 'vue-mixin-decorator';
import constants from '@/constants';
import { setTimeout } from 'timers';
import { ISimilarArticle } from '@/interfaces';

@Mixin
export default class ArticleCardMixin extends App {

    private childCardMessageJudge: boolean = false;
    private childCardMessage: string = '';

    private isShowContext: number = 0;
    private cardStyleMargin: boolean = false;


    private isGrayOut(articleId: string, removeArticleIds: string[]): boolean {
        if (removeArticleIds.includes(articleId)) {
            this.childCardMessageJudge = true;
            this.childCardMessage = '元に戻す';
            return true;
        } else {
            this.childCardMessageJudge = false;
            this.childCardMessage = 'チェック';
            return false;
        }
    }

    private showContext(titleIndex: number): void {
        if (this.isShowContext === titleIndex) {
            this.isShowContext = 0;
            this.cardStyleMargin = false;
        } else {
            this.isShowContext = titleIndex;
            this.cardStyleMargin = true;
        }
    }

    private toMemo(article: object): void {
        this.$store.commit('scrollYValue', window.pageYOffset);

        this.$store.commit('articleForMemo', article);
        this.$router.push({name: 'template-memo-page', query: {referer: constants.REFERER_SEARCH_RESULT}});
    }

    // 条文例をもっと見るページに行く
    private toSeeMore(article: object): void {
        this.$store.commit('scrollYValue', window.pageYOffset);
        this.$store.commit('articleForSeeMore', article);

        this.$router.push({
            name: constants.REFERER_SEE_MORE,
            query: {
                referer: constants.REFERER_SEE_MORE,
                contract_id: article.contract_id,
                article_id: article.article_id,
                option_articles: article.option_articles,
                related_documents: article.related_documents,
            },
        });
    }

    // フォーカス機能
    private goToParagraph(articleIndex: number, color: string): void {
        // storeの更新を待つ
        setTimeout(() => {
            // $store.articles.articles.indexからフォーカスを当てる条文を取得する
            const article = this.$store.getters.articles.articles.filter((similarArticle: ISimilarArticle) => similarArticle.index === articleIndex);
            // 存在しないindexを渡すパターンもしくはline_noが0以下の場合はフォーカスを当てない
            if (article.length === 0 || article[0].line_no <= 0) { return; }
            // フォーカスを当てるline_noを取得
            const lineNo = article[0].line_no;

            Word.run((context: Word.RequestContext) => {
                const paragraphs: Word.ParagraphCollection = context.document.body.paragraphs;
                context.load(paragraphs, 'items');

                return context.sync().then(() => {
                    const r: Word.Paragraph = paragraphs.items[lineNo];
                    r.select();
                    if (color === 'gray') {
                        r.font.highlightColor = constants.COLOR_GRAY;
                    } else if (color === 'default') {
                        r.font.highlightColor = '';
                    }
                });
            })
                .catch((error) => {
                    /* tslint:disable:no-console */
                    console.error(error);
                });
        }, 125);
    }

    private fixRemoveArticleIds(articleId: string, articleIndex: number, removeArticleIds: string[]): string[] {
        let fixedRemoveArticleIds: string[] = [''];
        if (this.isGrayOut(articleId, removeArticleIds)) {
            const filtered: string[] = removeArticleIds.filter((v) => {
                return v !== articleId;
            });
            fixedRemoveArticleIds = filtered;
            this.goToParagraph(articleIndex, 'default');
        } else {
            removeArticleIds.push(articleId);
            this.goToParagraph(articleIndex, 'gray');
            fixedRemoveArticleIds = removeArticleIds.filter((remove, index, array) => {
                return array.indexOf(remove) === index;
            });
        }
        return fixedRemoveArticleIds;
    }
}
