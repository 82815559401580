var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "searchBox" },
    [
      _c("h2", { staticClass: "title" }, [_vm._v("比較する契約書ファイル")]),
      _vm._v(" "),
      _vm._l(_vm.targetContracts, function(c) {
        return _c("div", { staticClass: "radioWrapper" }, [
          _c("input", {
            staticClass: "radioInput",
            attrs: {
              type: "radio",
              name: "applicableContract",
              id: "target_" + c.id
            },
            domProps: { value: c.id, checked: _vm.targetContractId === c.id },
            on: {
              change: function($event) {
                return _vm.selectTargetContractId(c.id)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "radioLabel", attrs: { for: "target_" + c.id } },
            [_c("span", { staticClass: "text" }, [_vm._v(_vm._s(c.name))])]
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }