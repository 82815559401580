import { render, staticRenderFns } from "./SearchResultInTemplatesPage.vue?vue&type=template&id=186f9453&scoped=true&"
import script from "./SearchResultInTemplatesPage.vue?vue&type=script&lang=ts&"
export * from "./SearchResultInTemplatesPage.vue?vue&type=script&lang=ts&"
import style0 from "./SearchResultInTemplatesPage.vue?vue&type=style&index=0&id=186f9453&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "186f9453",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('186f9453')) {
      api.createRecord('186f9453', component.options)
    } else {
      api.reload('186f9453', component.options)
    }
    module.hot.accept("./SearchResultInTemplatesPage.vue?vue&type=template&id=186f9453&scoped=true&", function () {
      api.rerender('186f9453', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/SearchResultInTemplatesPage.vue"
export default component.exports