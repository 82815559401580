var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "login" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "login__wrapper" }, [
      _c("h2", { staticClass: "login__title" }, [
        _vm._v("AI-CON Proは、自社専用のAI契約法務サービスです。")
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "login__button", on: { click: _vm.clickLoginBtn } },
        [_c("p", { staticClass: "login__button-text" }, [_vm._v("始める")])]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "login__text" }, [
        _vm._v(
          "※ボタンをクリックすると、ログイン用の別ウィンドウが表示されます。"
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "login__logo" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/logo-white.png"),
          alt: "AI-CON Pro"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "function" }, [
      _c("h2", { staticClass: "function__title" }, [_vm._v("主な機能")]),
      _vm._v(" "),
      _c("ul", { staticClass: "function__list" }, [
        _c("li", { staticClass: "function__list-text" }, [
          _vm._v("自社ひな型、AI-CON Proひな型との比較")
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "function__list-text" }, [
          _vm._v("同種条文の検索")
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "function__list-text" }, [
          _vm._v("条文単位でのコメント、解説の自動提案")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }