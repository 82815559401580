var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Loading", { attrs: { "finished-loading": _vm.finishedLoading } }),
      _vm._v(" "),
      _c("Header", {
        attrs: { "template-search-type": _vm.templateSearchType },
        on: { "change-template-search-type": _vm.changeTemplateSearchType }
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "container", on: { click: _vm.trackSelectionChanged } },
        [
          _c(
            "section",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isError,
                  expression: "!isError"
                }
              ],
              staticClass: "tabWrapper"
            },
            [
              _c("div", { staticClass: "slideWrapper" }, [
                _c(
                  "div",
                  {
                    staticClass: "slideButton slideButton-left",
                    on: { click: _vm.slideLeft }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/blackArrow_left.png"),
                        alt: "left"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "slideContentsWrapper" }, [
                  _c("h2", { staticClass: "slideContentsTitle" }, [
                    _vm._v(_vm._s(_vm.currentSectionTitle))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "slideContentsText" }, [
                    _vm._v(
                      _vm._s(_vm.currentSlideSelectionNumber + 1) +
                        "/" +
                        _vm._s(_vm.totalSlidesNumber) +
                        "通"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "slideButton slideButton-right",
                    on: { click: _vm.slideRight }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/blackArrow_right.png"),
                        alt: "right"
                      }
                    })
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "section",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isError,
                  expression: "!isError"
                }
              ],
              staticClass: "switchingTab"
            },
            [
              _c("ul", { staticClass: "switchingTabWrapper" }, [
                _c(
                  "li",
                  {
                    staticClass: "switchingTabItem",
                    class: { "-active": _vm.checkRequiredFlg === true },
                    on: {
                      click: function($event) {
                        return _vm.setCheckRequired(true)
                      }
                    }
                  },
                  [_vm._v("重要項目")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "switchingTabItem",
                    class: { "-active": _vm.checkRequiredFlg === false },
                    on: {
                      click: function($event) {
                        return _vm.setCheckRequired(false)
                      }
                    }
                  },
                  [_vm._v("その他項目")]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("ErrorBox", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isError,
                expression: "isError"
              }
            ],
            attrs: {
              "error-title-text": _vm.errorTitleText,
              "error-text": _vm.errorText,
              "title-br-judge": _vm.titleBrJudge
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isError,
                  expression: "!isError"
                }
              ]
            },
            [
              _vm.checkRequiredFlg === true
                ? _c("section", { staticClass: "cardContainer -required" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.requiredArticlesCount > 0,
                            expression: "requiredArticlesCount > 0"
                          }
                        ]
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "pullDown",
                            class: { "-open": _vm.isRequired === 1 },
                            on: {
                              click: function($event) {
                                return _vm.toggleRequired()
                              }
                            }
                          },
                          [
                            _c("h2", { staticClass: "textPink" }, [
                              _vm._v(
                                "契約書に不足している項目　" +
                                  _vm._s(_vm.requiredArticlesCount) +
                                  "件"
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "cardTitleArrow" }, [
                              _c("img", {
                                class: { "-none": _vm.isRequired !== 1 },
                                attrs: {
                                  src: require("../../../assets/pinkArrow_top.png")
                                }
                              }),
                              _vm._v(" "),
                              _c("img", {
                                class: { "-none": _vm.isRequired === 1 },
                                attrs: {
                                  src: require("../../../assets/pinkArrow_bottom.png")
                                }
                              })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cardWrapper" },
                          _vm._l(_vm.requiredArticles, function(a) {
                            return _c("RequiredArticleCard", {
                              key: a.article_id,
                              attrs: {
                                "article-id": _vm.getArticleId(
                                  "required",
                                  a.sort_order
                                ),
                                article: a,
                                "remove-article-ids": _vm.parentRemoveArticleIds
                              },
                              on: {
                                "change-parent-remove-article-ids":
                                  _vm.changeParentRemoveArticleIds
                              }
                            })
                          }),
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.requiredArticlesCount <= 0,
                            expression: "requiredArticlesCount <= 0"
                          }
                        ]
                      },
                      [_vm._m(0)]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.checkRequiredFlg === true
                ? _c("section", { staticClass: "cardContainer -delete" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.requiredDeleteArticlesCount > 0,
                            expression: "requiredDeleteArticlesCount > 0"
                          }
                        ]
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "pullDown",
                            class: { "-open": _vm.isDelete === 1 },
                            on: {
                              click: function($event) {
                                return _vm.toggleDelete(1)
                              }
                            }
                          },
                          [
                            _c("h2", { staticClass: "textPink" }, [
                              _vm._v(
                                "削除項目　" +
                                  _vm._s(_vm.requiredDeleteArticlesCount) +
                                  "件"
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "cardTitleArrow" }, [
                              _c("img", {
                                class: { "-none": _vm.isDelete !== 1 },
                                attrs: {
                                  src: require("../../../assets/pinkArrow_top.png")
                                }
                              }),
                              _vm._v(" "),
                              _c("img", {
                                class: { "-none": _vm.isDelete === 1 },
                                attrs: {
                                  src: require("../../../assets/pinkArrow_bottom.png")
                                }
                              })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cardWrapper" },
                          _vm._l(_vm.similarArticles, function(a) {
                            return _c("DeleteArticleCard", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: a.delete_required_flg,
                                  expression: "a.delete_required_flg"
                                }
                              ],
                              key: a.article_id,
                              attrs: {
                                "article-id": _vm.getArticleId(
                                  "delete",
                                  a.index
                                ),
                                article: a,
                                "remove-article-ids": _vm.parentRemoveArticleIds
                              },
                              on: {
                                "change-parent-remove-article-ids":
                                  _vm.changeParentRemoveArticleIds
                              }
                            })
                          }),
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.requiredDeleteArticlesCount <= 0,
                            expression: "requiredDeleteArticlesCount <= 0"
                          }
                        ]
                      },
                      [_vm._m(1)]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.similarArticles,
                      expression: "!similarArticles"
                    }
                  ],
                  staticClass: "textPink -center"
                },
                [_vm._v("契約書との類似の条文はありません。")]
              ),
              _vm._v(" "),
              _vm._l(_vm.similarArticles, function(a) {
                return _c("ArticleCard", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !a.delete_required_flg,
                      expression: "!a.delete_required_flg"
                    }
                  ],
                  key: a.article_id,
                  attrs: {
                    "article-id": _vm.getArticleId("normal", a.index),
                    article: a,
                    "check-required-flg": _vm.checkRequiredFlg,
                    "remove-article-ids": _vm.parentRemoveArticleIds
                  },
                  on: {
                    "change-parent-remove-article-ids":
                      _vm.changeParentRemoveArticleIds
                  }
                })
              })
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("LogOutButton")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pullDown" }, [
      _c("h2", { staticClass: "textPink" }, [
        _vm._v("契約書に不足している項目はありません。")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pullDown" }, [
      _c("h2", { staticClass: "textPink" }, [
        _vm._v("契約書から削除すべき項目はありません。")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }