import 'babel-polyfill';
import Vue from 'vue';
import VModal from 'vue-js-modal';
import VueCookies from 'vue-cookies';
import VueIntercom from 'vue-intercom';

import router from '@/router';
import store from '@/store';
import App from '@/App.vue';

import createAuth0Client from '@auth0/auth0-spa-js';
import * as Config from '@/config';

Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
Vue.use(VueCookies);
Vue.use(VueIntercom, { appId: Config.INTERCOM_APP_ID });

Office.initialize = async () => {
    const auth0 = await createAuth0Client({
        domain: Config.AUTH0_DOMAIN,
        client_id: Config.AUTH0_CLIENT_ID,
        audience: Config.AUTH0_AUDIENCE,
        redirect_uri: Config.AUTH0_REDIRECT_URI,
    });

    new Vue({
        router,
        store,
        data: {auth0},
        components: {App},
        template: '<App/>',
    }).$mount('#app');
};
