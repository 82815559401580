






















































import { Component } from 'vue-property-decorator';
import Loading from '@molecules/Loading.vue';
import Header from '@organisms/Header.vue';
import SeeMoreArticleCard from '@organisms/SeeMoreArticleCard.vue';
import LogOutButton from '@molecules/LogOutButton.vue';
import local from '@/constants/local';
import constants from '@/constants';
import {ISeeMoreArticle, ISimilarArticle} from '@/interfaces';
import * as Config from '@/config';
import Auth from '@/Auth.vue';
import axios from 'axios';

@Component({
    components: {
        Loading,
        Header,
        SeeMoreArticleCard,
        LogOutButton,
    },
})
export default class SeeMorePage extends Auth {
    private templateSearchType: number = this.$store.getters.templateSearchType;
    private isOpenedSuppliersWrapper?: boolean = false;

    private originalArticleInTemplate = this.$store.getters.articleForSeeMore;

    private filterSupplierName: string = '';
    private suppliers: string[] = [];
    private articles: ISeeMoreArticle[] = [];

    // インスタンスがマウントされた後
    public async mounted() {
        await this.setSeeMoreArticles(this.templateSearchType);
    }

    // ひな型の切り替え
    private changeTemplateSearchType(templateSearchType: number): void {
        this.$store.commit('templateSearchType', templateSearchType);
    }

    private toggleSuppliersWrapper(): void {
        this.isOpenedSuppliersWrapper = !this.isOpenedSuppliersWrapper;
    }

    private parseParamsToGetArticlesByQuery(query: object): object {
        const ret: object = {
            contract_id: [],
            option_articles: [],
            related_documents: [],
        };
        ret.contract_id = parseInt(query.contract_id, constants.DECIMAL_SYSTEM);
        ret.article_id = parseInt(query.article_id, constants.DECIMAL_SYSTEM);

        if (query.option_articles) {
            if (typeof query.option_articles === 'string') {
                ret.option_articles = [parseInt(query.option_articles, constants.DECIMAL_SYSTEM)];
            } else {
                ret.option_articles = query.option_articles.map( (str) => parseInt(str, constants.DECIMAL_SYSTEM) );
            }
        }
        if (query.related_documents) {
            if (typeof query.related_documents === 'string') {
                ret.related_documents = [parseInt(query.related_documents, constants.DECIMAL_SYSTEM)];
            } else {
                ret.related_documents = query.related_documents.map( (str) => parseInt(str, constants.DECIMAL_SYSTEM) );
            }
        }
        return ret;
    }

    private getUrlToGetMoreArticles(templateSearchType: number): string {
        const searchType: string = this.getSearchTypes(templateSearchType);
        return `https://${Config.API_DOMAIN}/api/v1.0/${searchType}/more_article_examples`;
    }

    private async setSeeMoreArticles(templateSearchType: number): void {
        // stand-alone時のみ、デモ版としての情報を取得する
        if (Config.IS_STANDALONE) {
            // article_labelsを紐づけして条文例をもっとよく見る
            const params: object = this.parseParamsToGetArticlesByQuery(this.$route.query);
            const pl = JSON.stringify(params.article_label);
            const standAloneArticles: ISeeMoreArticle[] = local.DATA_SEE_MORE_ARTICLES.articles;

            this.articles = standAloneArticles.filter((article) => {
                const al = JSON.stringify(article.article_label);
                if (al === pl) {
                    return article;
                }
            });

            // 取引先の分岐
            if (templateSearchType === constants.TEMPLATE_SEARCH_TYPE_CUSTOMER) {
                this.suppliers = local.DATA_SEE_MORE_ARTICLES.suppliers;
            } else {
                this.suppliers = [];
            }
        } else {
            let params: object;

            try {
                params = this.parseParamsToGetArticlesByQuery(this.$route.query);
            } catch (e) {
                return;
            }

            const url: string = this.getUrlToGetMoreArticles(this.templateSearchType);
            const accessToken: any = await this.getAccessToken();
            const headers: any = this.setAccessTokenToAuthorizationHeader({
                'Content-Type': 'application/json',
            }, accessToken);
            const config: object = {headers};

            axios.post(url, params, config)
              .then((res) => {
                  this.suppliers = res.data.suppliers;
                  this.articles = res.data.articles;
              })
              .catch((e) => {
                  /* tslint:disable:no-console */
                  console.log(e);
              });
        }
    }

    private backWithQuery(): void {
        this.$router.push({name: 'search-result-in-templates-page', query: {referer: 'see-more-page'}});
    }

    private setFilterSupplierName(filterSupplierName: string): void {
        this.filterSupplierName = filterSupplierName;
        this.isOpenedSuppliersWrapper = false;
    }

    private get filteredArticles(): any {
        let articles: ISeeMoreArticle[] = this.articles;
        if (this.filterSupplierName) {
            articles = articles.filter((article) => {
                if (article.supplier_name === this.filterSupplierName) {
                    return article;
                }
            });
        }
        return articles;
    }
}
