




















import { Component, Prop } from 'vue-property-decorator';
import App from '@/App.vue';

@Component
export default class Loading extends App {
    @Prop({default: false})
    private readonly finishedLoading!: boolean;
}
