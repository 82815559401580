var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "pageLoadingAnimationWrapper",
      class: { "-none": _vm.finishedLoading === true }
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pageLoadingAnimationPartsWrapper" }, [
      _c("div", { staticClass: "PartsWrapper" }, [
        _c("div", { staticClass: "loadingAnimationParts" }),
        _vm._v(" "),
        _c("div", { staticClass: "loadingAnimationParts" }),
        _vm._v(" "),
        _c("div", { staticClass: "loadingAnimationParts" }),
        _vm._v(" "),
        _c("div", { staticClass: "loadingAnimationParts" }),
        _vm._v(" "),
        _c("div", { staticClass: "loadingAnimationParts" }),
        _vm._v(" "),
        _c("div", { staticClass: "loadingAnimationParts" }),
        _vm._v(" "),
        _c("div", { staticClass: "loadingAnimationParts" }),
        _vm._v(" "),
        _c("div", { staticClass: "loadingAnimationParts" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pageLoadingAnimationTextWrapper" }, [
        _c("p", { staticClass: "pageLoadingAnimationText" }, [
          _vm._v("読み込み中")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }