var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "seeMorePage" } },
    [
      _c("Header", {
        attrs: { "template-search-type": _vm.templateSearchType },
        on: { "change-template-search-type": _vm.changeTemplateSearchType }
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "container" },
        [
          _c("section", { staticClass: "backPage" }, [
            _c("div", { staticClass: "backPageWrapper" }, [
              _c(
                "div",
                {
                  staticClass: "backPageButton",
                  on: {
                    click: function($event) {
                      return _vm.backWithQuery()
                    }
                  }
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("span", { staticClass: "backPageText" }, [
                    _vm._v(_vm._s(_vm.originalArticleInTemplate.article_title))
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _vm.suppliers.length > 0
            ? _c("div", { staticClass: "suppliersDateSelect" }, [
                _c(
                  "h2",
                  {
                    staticClass: "suppliersDateSelectTitleWrapper",
                    on: { click: _vm.toggleSuppliersWrapper }
                  },
                  [
                    _c("p", { staticClass: "suppliersDateSelectTitle" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.filterSupplierName === "",
                              expression: "filterSupplierName === ''"
                            }
                          ]
                        },
                        [_vm._v("取引先")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.filterSupplierName !== "",
                              expression: "filterSupplierName !== ''"
                            }
                          ]
                        },
                        [_vm._v(_vm._s(_vm.filterSupplierName))]
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "suppliersDateSelectArrow" }, [
                      _c("img", {
                        class:
                          _vm.isOpenedSuppliersWrapper === false ? "" : "-none",
                        attrs: {
                          src: require("../../../assets/blueArrow_bottom.png")
                        }
                      }),
                      _vm._v(" "),
                      _c("img", {
                        class:
                          _vm.isOpenedSuppliersWrapper === false ? "-none" : "",
                        attrs: {
                          src: require("../../../assets/blueArrow_top.png")
                        }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isOpenedSuppliersWrapper === true,
                        expression: "isOpenedSuppliersWrapper === true"
                      }
                    ],
                    staticClass: "suppliersDateSelectList"
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "suppliersDateSelectItem",
                        on: {
                          click: function($event) {
                            return _vm.setFilterSupplierName("")
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "suppliersDateSelectItemText" },
                          [_vm._v("選択解除")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.suppliers, function(supplier) {
                      return _c(
                        "li",
                        {
                          staticClass: "suppliersDateSelectItem",
                          on: {
                            click: function($event) {
                              return _vm.setFilterSupplierName(supplier)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "suppliersDateSelectItemText" },
                            [_vm._v(_vm._s(supplier))]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.filteredArticles, function(a, index) {
            return _c("SeeMoreArticleCard", {
              key: a.articleId,
              attrs: {
                "article-id": index,
                article: a,
                "original-article": _vm.originalArticleInTemplate
              }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("LogOutButton")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "backPageArrow" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/back_arrow.png"),
          alt: "前のページへ戻ります"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }