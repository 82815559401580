





































import {Component, Prop} from 'vue-property-decorator';
import router from '@/router';
import {ISeeMoreArticle} from '@/interfaces';
import constants from '@/constants';
import App from '@/App.vue';

@Component
export default class SeeMoreArticleCard extends App {

    @Prop({default: {}})
    private readonly article?: ISeeMoreArticle;

    @Prop({default: 0})
    private readonly articleId?: number;

    private templateSearchType?: number = this.$store.getters.templateSearchType;

    private readonly originalArticleInTemplate?: object = this.$store.getters.articleForSeeMore;

    private isShowContext?: boolean = false;

    private cardStyleMargin?: boolean = false;

    private toMemo(article: object): void {
        this.$store.commit('articleForMemo', article);
        // memoから戻ってきた時用にqueryを保持しておく
        this.$router.push({
            name: constants.REFERER_TEMPLATE_MEMO,
            query: {
                referer: constants.REFERER_SEE_MORE,
                contract_id: this.originalArticleInTemplate.contract_id.toString(constants.DECIMAL_SYSTEM),
                article_id: this.originalArticleInTemplate.article_id.toString(constants.DECIMAL_SYSTEM),
                option_articles: this.originalArticleInTemplate.option_articles.map((num) => num.toString(constants.DECIMAL_SYSTEM)),
                related_documents: this.originalArticleInTemplate.related_documents.map((num) => num.toString(constants.DECIMAL_SYSTEM)),
            },
        });
    }

    private showContext(): void {
        if (this.isShowContext === false) {
            this.isShowContext = true;
            this.cardStyleMargin = false;
        } else {
            this.isShowContext = false;
            this.cardStyleMargin = true;
        }
    }
}
