var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "LogOutButton",
      on: {
        click: function($event) {
          return _vm.logout()
        }
      }
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "LogOutButton__text" }, [
      _vm._v("ログアウト\n        "),
      _c("span", { staticClass: "LogOutButton__text-arrow" }, [
        _c("img", {
          attrs: {
            src: require("../../../assets/blackArrow_right.png"),
            alt: "ログアウト"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }