var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "cardContainer" }, [
    _c(
      "div",
      {
        staticClass: "cardWrapper",
        class: _vm.templateSearchType === 1 ? "-template" : ""
      },
      [
        _c("div", { staticClass: "cardBox" }, [
          _c(
            "div",
            {
              staticClass: "cardTitleContainer",
              class: { "-margin": _vm.isShowContext !== _vm.articleId }
            },
            [
              _c(
                "div",
                {
                  staticClass: "cardTitleWrapper",
                  on: {
                    click: function($event) {
                      return _vm.showContext(_vm.articleId)
                    }
                  }
                },
                [
                  _c("h2", { staticClass: "cardTitle" }, [
                    _vm._v(_vm._s(_vm.article.article_title))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "cardTitleArrow" }, [
                    _c("img", {
                      class: { "-none": _vm.isShowContext === false },
                      attrs: {
                        src: require("../../../assets/blueArrow_top.png")
                      }
                    }),
                    _vm._v(" "),
                    _c("img", {
                      class: { "-none": _vm.isShowContext === true },
                      attrs: {
                        src: require("../../../assets/blueArrow_bottom.png")
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.article.memo,
                      expression: "article.memo"
                    }
                  ],
                  staticClass: "goToMemo",
                  on: {
                    click: function($event) {
                      return _vm.toMemo(_vm.article)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/memo.png"),
                      alt: "解説"
                    }
                  }),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "goToMemoText" }, [_vm._v("解説")])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "articleContent",
              class: _vm.isShowContext === false ? "-none" : ""
            },
            [
              _c("p", { staticClass: "articleContentText" }, [
                _vm._v(_vm._s(_vm.article.article_body))
              ])
            ]
          ),
          _vm._v(" "),
          !(_vm.article.one_phrase_comment === "")
            ? _c(
                "div",
                {
                  staticClass: "onePhraseComment",
                  class: { "-margin": _vm.cardStyleMargin === true }
                },
                [
                  _c("p", { staticClass: "onePhraseCommentText" }, [
                    _vm._v(_vm._s(_vm.article.one_phrase_comment))
                  ])
                ]
              )
            : _vm._e()
        ])
      ]
    ),
    _vm._v(" "),
    _vm.article.suppliers !== ""
      ? _c("div", { staticClass: "suppliersDate" }, [
          _vm.article.supplier_name !== "" &&
          _vm.article.date_of_conclusion !== ""
            ? _c("p", { staticClass: "suppliersDateText" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.article.supplier_name) +
                    "：" +
                    _vm._s(_vm.article.date_of_conclusion) +
                    "\n        "
                )
              ])
            : _vm.article.supplier_name !== ""
            ? _c("p", { staticClass: "suppliersDateText" }, [
                _vm._v(_vm._s(_vm.article.supplier_name))
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }