















import { Component, Prop, Emit } from 'vue-property-decorator';
import { IPositions } from '@/interfaces';
import App from '@/App.vue';

@Component
export default class PositionForm extends App {
    @Prop({default: {}})
    private readonly positions!: IPositions[];

    @Prop({default: 0})
    private readonly selectedPosition!: number;

    private childSelectedPosition: number = this.selectedPosition;

    @Emit()
    private selectPosition(position: number): void {
        this.childSelectedPosition = position;
    }
}
