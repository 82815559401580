













import { Component, Prop } from 'vue-property-decorator';
import App from '@/App.vue';

@Component
export default class ErrorBox extends App {
    @Prop({default: ''})
    private readonly errorTitleText!: string;

    private childErrorTitleText: string = this.errorTitleText;

    @Prop({default: ''})
    private readonly errorText!: string;

    @Prop({default: false})
    private titleBrJudge!: boolean;
}
