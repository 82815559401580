





















import { Component } from 'vue-property-decorator';
import Auth from '@/Auth.vue';
import { IS_STANDALONE } from '@/config';
import constants from '@/constants';

@Component
export default class LoginPage extends Auth {
    // 「始める」ボタンを押したときの処理
    private clickLoginBtn(): void {
        // yarn start:stand-alone で起動したときの処理
        if (IS_STANDALONE) {
            this.$router.push({
                name: constants.REFERER_SEARCH
            });
        } else {
            // それ以外の処理＝Auth0のログインページをポップアップで出す処理
            this.loginToAuth0();
        }
    }
}
