var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "searchBox" }, [
    _c("h2", { staticClass: "title" }, [_vm._v("立場")]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "positionWrapper" },
      _vm._l(_vm.positions.positions, function(p) {
        return _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: p.has_contract,
                expression: "p.has_contract"
              }
            ],
            staticClass: "positionButton",
            class: { "-active": _vm.selectedPosition === p.id },
            on: {
              click: function($event) {
                return _vm.selectPosition(p.id)
              }
            }
          },
          [_vm._v(_vm._s(p.name) + "\n    ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }