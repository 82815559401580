

















import { Component, Prop, Emit } from 'vue-property-decorator';
import App from '@/App.vue';

@Component
export default class TargetContracts extends App {

    @Prop({default: []})
    private readonly targetContracts!: object[];

    @Prop({default: 0})
    private readonly targetContractId!: number;

    private childTargetContractId: number = this.targetContractId;

    @Emit()
    private selectTargetContractId(selectedTargetContractIdNumber: number): void {
        this.childTargetContractId = selectedTargetContractIdNumber;
    }
}
