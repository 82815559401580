import Vuex, {MutationTree} from 'vuex';
import Vue from 'vue';
import {IArticles} from '@/interfaces';

Vue.use(Vuex);

interface State {
    // header
    templateSearchType: number;
    // search
    targetContractId: number;
    targetContracts: [];
    // search result
    checkRequiredFlg: boolean;
    parentRemoveArticleIds: string[];
    articles: IArticles;

    // memo
    articleForMemo: object;

    // seeMore
    articleForSeeMore: object;

    // cardMessage
    cardMessage: string;
    cardMessageJudge: boolean;

    // isRequired
    isRequired: number;

    // isDelete
    isDelete: number;

    // scrollYValue
    scrollYValue: number;
}

export const state: State = {
    // header
    templateSearchType: 1,
    // search
    targetContractId: 0,
    targetContracts: [],
    // search result
    checkRequiredFlg: false,
    parentRemoveArticleIds: [],
    articles: {articles: [], required_articles: []},

    // memo
    articleForMemo: {},

    // seeMore
    articleForSeeMore: {},

    // cardMessage
    cardMessage: 'チェック',
    cardMessageJudge: false,

    // isRequired
    isRequired: 0,

    // isDelete
    isDelete: 0,

    // scrollYValue
    scrollYValue: 0,
};

export const getters = {
    // header
    templateSearchType: (s: State) => s.templateSearchType,
    // search
    targetContractId: (s: State) => s.targetContractId,
    targetContracts: (s: State) => s.targetContracts,
    // search result
    checkRequiredFlg: (s: State) => s.checkRequiredFlg,
    parentRemoveArticleIds: (s: State) => s.parentRemoveArticleIds,
    articles: (s: State) => s.articles,

    // memo
    articleForMemo: (s: State) => s.articleForMemo,

    // seeMore
    articleForSeeMore: (s: State) => s.articleForSeeMore,

    // cardMessage
    cardMessage: (s: State) => s.cardMessage,
    cardMessageJudge: (s: State) => s.cardMessageJudge,

    // isRequired
    isRequired: (s: State) => s.isRequired,

    // isDelete
    isDelete: (s: State) => s.isDelete,

    // scrollYValue
    scrollYValue: (s: State) => s.scrollYValue,
};

export const mutations: MutationTree<State> = {
    // header
    templateSearchType: (s, data: number) => {s.templateSearchType = data; },
    // search
    targetContractId: (s, data: number) => {s.targetContractId = data; },
    targetContracts: (s, data: []) => {s.targetContracts = data; },
    // search result
    checkRequiredFlg: (s, data: boolean) => {s.checkRequiredFlg = data; },
    parentRemoveArticleIds: (s, data: []) => {s.parentRemoveArticleIds = data; },
    articles: (s, data: IArticles) => {s.articles = data; },
    // memo
    articleForMemo: (s, data: object) => {s.articleForMemo = data; },
    // seeMore
    articleForSeeMore: (s, data: object) => { s.articleForSeeMore = data; },
    // cardMessage
    cardMessage: (s, data: string) => { s.cardMessage = data; },
    cardMessageJudge: (s, data: boolean) => { s.cardMessageJudge = data; },
    // isRequired
    isRequired: (s, data: number) => { s.isRequired = data; },
    // isDelete
    isDelete: (s, data: number) => { s.isDelete = data; },
    // scrollYValue
    scrollYValue: (s, data: number) => { s.scrollYValue = data; },
};

export default new Vuex.Store<State>({
    state,
    getters,
    mutations,
});
