
















































































































import { Component } from 'vue-property-decorator';
import ErrorBox from '@molecules/ErrorBox.vue';
import Loading from '@molecules/Loading.vue';
import Header from '@organisms/Header.vue';
import DeleteArticleCard from '@organisms/DeleteArticleCard.vue';
import RequiredArticleCard from '@organisms/RequiredArticleCard.vue';
import ArticleCard from '@organisms/ArticleCard.vue';
import LogOutButton from '@molecules/LogOutButton.vue';
import constants from '@/constants';
import local from '@/constants/local';
import * as Config from '@/config';

import axios from 'axios';
import {ITargetContract, IArticles, ISimilarArticle, IRequiredArticle} from '@/interfaces';
import Auth from '@/Auth.vue';

@Component({
    components: {
        ErrorBox,
        RequiredArticleCard,
        DeleteArticleCard,
        ArticleCard,
        Loading,
        Header,
        LogOutButton,
    },
})
export default class SearchResultInTemplatesPage extends Auth {
    // エラー表示のための変数
    private errorTitleText: string = 'エラーが発生したため表示することができません';
    private errorText: string = 'アドインを再読み込み後、もう一度お試しください。';
    private titleBrJudge: boolean = true;

    private get totalSlidesNumber(): number {
        return this.targetContracts.length;
    }

    private get currentSectionTitle(): string {
        if (!this.targetContractId || this.targetContractId <= 0) {
            return '';
        }
        const currentContract = this.targetContracts.filter((item) => {
            return item.id === this.targetContractId;
        });
        if (currentContract[0]) {
            return currentContract[0].name;
        }
        return '';
    }

    private get requiredArticlesCount(): number {
        if (this.requiredArticles) {
            return this.requiredArticles.length;
        } else {
            return 0;
        }
    }

    private get requiredDeleteArticlesCount(): number {
        if (this.similarArticles) {
            const requiredDeleteArticles: ISimilarArticle[] = this.similarArticles.filter((item) => {
                return item.delete_required_flg === true;
            });
            return requiredDeleteArticles.length;
        } else {
            return 0;
        }
    }

    private get similarArticles(): ISimilarArticle[] {
        return this.articles.articles;
    }

    private get requiredArticles(): IRequiredArticle[] {
        return this.articles.required_articles;
    }

    private parentRemoveArticleIds: string[] = [];

    private templateSearchType?: number = this.$store.getters.templateSearchType;
    private isRequired: number = this.$store.getters.isRequired;
    private isDelete: number = this.$store.getters.isDelete;
    private checkRequiredFlg: boolean = true;
    private finishedLoading: boolean = false;
    private currentSlideSelectionNumber: number = 0;

    private articles: IArticles = {articles: [], required_articles: []};

    private targetContractId: number = this.$store.getters.targetContractId;
    private targetContracts: ITargetContract[] = this.$store.getters.targetContracts;

    private cardMessageJudge: boolean = false;
    private cardMessage: string = 'チェック';

    private isError: boolean = false;

    private wordText: string = '';
    private wordTextEnterCount: number = 0;

    public mounted(): void {
        this.setCurrentSlideSelectionNumber();
        if (this.withNoLoad(this.$route.query.referer)) {
            // storeから取ってくる
            this.getElementsFromStoreToShowPage();
        } else {
            // apiへの通信
            this.setArticles();
        }

        const referer: string|Array<string|null> = this.$route.query.referer;
        if (referer === constants.REFERER_SEARCH) {
            window.scroll(0, 0);
        }

        this.startTrackingEnter();
    }

    public updated(): void {
        const referer: string|Array<string|null> = this.$route.query.referer;
        const scrollYValue: number = this.$store.getters.scrollYValue;

        if (referer === constants.REFERER_SEARCH) {
            window.scroll(0, 0);
        } else {
            window.scroll(0, scrollYValue);
        }
    }

    public destroyed(): void {
        if (!this.withNoLoad(this.$route.name)) {
            // ユーザー操作の捕捉を終了する
            Office.context.document.removeHandlerAsync('documentSelectionChanged', this.trackSelectionChanged);
        }
    }

    public getSlice(state: any): Promise<any> {
        const vm: any = this;

        return new Promise<any>((resolve, reject) => {

            state.file.getSliceAsync(state.counter, (result: Office.AsyncResult<Office.Slice>) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    let data: any = result.value.data;
                    if (data) {
                        data = btoa(new Uint8Array(data).reduce((d, byte) => {
                            return d + String.fromCharCode(byte);
                        }, ''));
                        sessionStorage.setItem(state.dst, (sessionStorage.getItem(state.dst) || '') + data);

                        // if there are more slices, repeat
                        state.counter++;
                        if (state.counter < state.sliceCount) {
                            vm.getSlice(state)
                                .then((e) => {resolve(e); })
                                .catch((e) => {reject(e); });
                        } else {
                            state.file.closeAsync((sliceResult: Office.AsyncResult<void>) => {
                                resolve(sliceResult.status);
                            });
                        }
                    }
                } else {
                    reject(result.status);
                }
            });
        });
    }

    public readFile(dst: string): any {
        return new Promise<any>((resolve, reject) => {
            const vm: any = this;

            // cleanup
            if (sessionStorage.getItem(dst)) {
                sessionStorage.removeItem(dst);
            }

            // TODO handle multi slice base64 encoding correctly
            Office.context.document.getFileAsync(Office.FileType.Compressed,
                (result: Office.AsyncResult<Office.File>) => {

                    if (result.status === Office.AsyncResultStatus.Succeeded) {

                        // Get the File object from the result.
                        const myFile: Office.File = result.value;

                        // state setup
                        const state: object = {
                            file: myFile,
                            counter: 0,
                            sliceCount: myFile.sliceCount,
                            dst,
                        };

                        vm.getSlice(state)
                            .then((e) => { resolve(e); })
                            .catch((e) => { reject(e); });
                    } else {
                        reject(result.status);
                    }
                });
        });
    }

    // ユーザー操作の捕捉の開始と初期状態のwordTextの文章と改行数の保持
    private startTrackingEnter(): void {
        // 初期状態のwordTextの文章と改行数を保持する
        Word.run((context: Word.RequestContext) => {
            const body = context.document.body;
            context.load(body, 'text');
            return context.sync().then(() => {
                this.wordText = body.text;
                this.wordTextEnterCount = this.getWordTextSplitByEnter(body.text).length;
            });
        });

        if (!this.withNoLoad(this.$route.query.referer)) {
            // ユーザー操作の捕捉を開始する
            Office.context.document.addHandlerAsync('documentSelectionChanged', this.trackSelectionChanged);
        }
    }

    // ユーザー操作を捕捉した場合に実行する関数
    private trackSelectionChanged(): void {
        Word.run((context: Word.RequestContext) => {
            const body = context.document.body;
            context.load(body, 'text');
            return context.sync().then(() => {
                const newText: string = body.text;
                const wordTextEnterCount: number = this.getWordTextSplitByEnter(newText).length;

                // 改行数に差異があるか
                if (this.wordTextEnterCount !== wordTextEnterCount) {
                    // 改行位置を算出
                    const modifiedEnterPosition: number = this.getModifiedEnterPosition(this.wordText, newText);
                    // 改行数を算出（insertedの場合は正、deletedの場合は負）
                    const modifiedEnterCount: number = wordTextEnterCount - this.wordTextEnterCount;
                    // this.articles.artciles.line_noを更新し、storeにcommitする
                    this.updateArticlesLineNo(modifiedEnterPosition, modifiedEnterCount);
                }

                // 変更後のwordTextの文章と改行数を保持する
                this.wordText = newText;
                this.wordTextEnterCount = wordTextEnterCount;
            });
        });
    }

    // 与えられたテキストを改行コードで分割する
    private getWordTextSplitByEnter(text: string = ''): string[] {
        return text.split(/\r\f|\r\n|\t|\r/g);
    }

    // 改行位置を算出する
    private getModifiedEnterPosition(oldText: string, newText: string): number {
        const oldTextEnterSplitArray: string[] = this.getWordTextSplitByEnter(oldText);
        const newTextEnterSplitArray: string[] = this.getWordTextSplitByEnter(newText);

        const oldTextEnterCount: number = oldTextEnterSplitArray.length; // 編集前のword本文の改行数
        const newTextEnterCount: number = newTextEnterSplitArray.length; // 編集後のword本文の改行数

        let modifiedEnterPosition: number = 0;

        // 改行位置を算出する
        // 改行で分割した各テキストを比較した場合に初めて差異が生じた場所が改行位置となる
        if (oldTextEnterCount > newTextEnterCount) {
            // 編集前の改行数が多い場合（deleted時）はoldTextEnterSplitArrayを回す（newTextEnterSplitArray[index] === undifinedを避けるため）
            oldTextEnterSplitArray.some((value, index) => {
                if (value !== newTextEnterSplitArray[index]) {
                    modifiedEnterPosition = index;
                    return true;
                }
                return false;
            });
        } else {
            // 編集後の改行数が多い場合（inserted時）はnewTextEnterSplitArrayを回す（oldTextEnterSplitArray[index] === undifinedを避けるため）
            newTextEnterSplitArray.some((value, index) => {
                if (value !== oldTextEnterSplitArray[index]) {
                    modifiedEnterPosition = index;
                    return true;
                }
                return false;
            });
        }

        return modifiedEnterPosition;
    }

    // this.articles.artciles.line_noを更新し、storeにcommitする
    private updateArticlesLineNo(modifiedEnterPosition: number, modifiedEnterCount: number) {
        const similarArticles: ISimilarArticle[] = this.articles.articles;
        this.articles.articles = similarArticles.map((similarArticle) => {
            if (similarArticle.line_no >= modifiedEnterPosition) {
                similarArticle.line_no += modifiedEnterCount;
                return similarArticle;
            }
            return similarArticle;
        });

        this.$store.commit('articles', this.articles);
    }

    private changeTemplateSearchType(templateSearchType: number): void {
        this.$store.commit('templateSearchType', templateSearchType);
    }

    private slideRight(): void {
        const next: number = this.currentSlideSelectionNumber + 1;
        this.currentSlideSelectionNumber = next >= this.targetContracts.length ? 0 : next;
        this.targetContractId = this.targetContracts[this.currentSlideSelectionNumber].id;
        this.setArticles();
    }

    private slideLeft(): void {
        const prev: number = this.currentSlideSelectionNumber - 1;
        this.currentSlideSelectionNumber = prev < 0 ? this.targetContracts.length - 1 : prev;
        this.targetContractId = this.targetContracts[this.currentSlideSelectionNumber].id;
        this.setArticles();
    }

    private toggleRequired(): void {
        if (this.isRequired === 0) {
            this.isRequired = 1;
        } else {
            this.isRequired = 0;
        }
        this.$store.commit('isRequired', this.isRequired);
    }

    private toggleDelete(): void {
        if (this.isDelete === 0) {
            this.isDelete = 1;
        } else {
            this.isDelete = 0;
        }
        this.$store.commit('isDelete', this.isDelete);
    }

    private setCheckRequired(checkRequiredFlg: boolean): void {
        this.checkRequiredFlg = checkRequiredFlg;
        this.commitElementsToShowPage();
    }

    // チェック機能
    private changeParentRemoveArticleIds(removeArticleIds: string[]): void {
        this.parentRemoveArticleIds = removeArticleIds;
        this.commitElementsToShowPage();

        this.$store.commit('scrollYValue', window.pageYOffset);
        window.scroll(0, window.pageYOffset);
    }

    private setCurrentSlideSelectionNumber(): void {
        this.targetContracts.filter((item, idx) => {
            if (item.id === this.targetContractId) {
                this.currentSlideSelectionNumber = idx;
            }
        });
    }

    private async setArticles(): Promise<void> {
        this.finishedLoading = false;
        this.initializeParentRemoveArticleIds();

        if (Config.IS_STANDALONE) {
            this.finishedLoading = true;
            this.articles = {
                required_articles: local.DATA_SEARCH_RESULT_REQUIRED_ARTICLES,
                articles: local.DATA_SEARCH_RESULT_SIMILAR_ARTICLES,
            };
            this.commitElementsToShowPage();
        } else {
            const dst: string = 'item';
            const timeout: number = 60000;
            const accessToken: any = await this.getAccessToken();
            const headers: any = this.setAccessTokenToAuthorizationHeader({
                'Content-Type': 'multipart/form-data',
            }, accessToken);

            this.readFile(dst)
                .then(() => {
                    const url: string = `https://${Config.API_DOMAIN}/api/v1.0/contracts`;
                    const params: FormData = new FormData();

                    params.append('contract_id', this.targetContractId);
                    params.append('search_type', this.getSearchTypes(this.templateSearchType));

                    // TODO debug
                    // params.append("contract_id", "1");
                    // params.append("search_type", "gvatech");

                    params.append('upload_contract', sessionStorage.getItem(dst));

                    const config: object = {
                        headers,
                        timeout,
                    };

                    axios.post(url, params, config)
                        .then((res) => {
                            // DEBUG用
                            /* tslint:disable:no-console */
                            console.log(res.data);

                            localStorage.removeItem(dst);
                            this.articles = res.data;
                            this.finishedLoading = true;
                            this.commitElementsToShowPage();
                        })
                        .catch((e) => {
                            /* tslint:disable:no-console */
                            console.error(e);
                            localStorage.removeItem(dst);
                            this.finishedLoading = true;
                            this.isError = true;
                        });
                })
                .catch((e) => {
                    /* tslint:disable:no-console */
                    console.error(e);
                    this.finishedLoading = true;
                    this.isError = true;
                });
        }
    }

    private getArticleId(articleType: string, key: number): string {
        let articleId: string = '';
        if (articleType === 'required') {
            articleId += 'r';
        } else if (articleType === 'delete') {
            articleId += 'd';
        } else if (articleType === 'normal') {
            articleId += 'n';
        } else {
            return '';
        }
        return articleId + String(key);
    }

    private commitElementsToShowPage(): void {
        this.$store.commit('articles', this.articles);
        this.$store.commit('parentRemoveArticleIds', this.parentRemoveArticleIds);
        this.$store.commit('checkRequiredFlg', this.checkRequiredFlg);
        this.$store.commit('targetContractId', this.targetContractId);
        this.$store.commit('targetContracts', this.targetContracts);
        this.$store.commit('cardMessage', this.cardMessage);
        this.$store.commit('cardMessageJudge', this.cardMessageJudge);
        this.$store.commit('isRequired', this.isRequired);
        this.$store.commit('isDelete', this.isDelete);
    }

    private withNoLoad(referer: any): boolean {
        if (referer === 'template-memo-page' || referer === 'see-more-page') {
            return true;
        } else {
            return false;
        }
    }

    private getElementsFromStoreToShowPage(): void {
        this.articles = this.$store.getters.articles;
        this.parentRemoveArticleIds = this.$store.getters.parentRemoveArticleIds;
        this.checkRequiredFlg = this.$store.getters.checkRequiredFlg;
        this.targetContractId = this.$store.getters.targetContractId;
        this.targetContracts = this.$store.getters.targetContracts;
        this.finishedLoading = true;
        this.cardMessage = this.$store.getters.cardMessage;
        this.cardMessageJudge = this.$store.getters.cardMessageJudge;
        this.isRequired = this.$store.getters.isRequired;
        this.isDelete = this.$store.getters.isDelete;
    }

    private initializeParentRemoveArticleIds(): void {
        this.parentRemoveArticleIds = [];
        this.$store.commit('parentRemoveArticleIds', this.parentRemoveArticleIds);
    }
}
