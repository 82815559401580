export let IS_STANDALONE = false;
export let IS_TEST = false;
export let API_DOMAIN = '';

export let LOGIN_DIALOG_DOMAIN = '';
export let AUTH0_DOMAIN = '';
export let AUTH0_CLIENT_ID = '';
export let AUTH0_REDIRECT_URI = '';
export let INTERCOM_APP_ID = '';
export let AUTH0_AUDIENCE = '';

switch (process.env.NODE_ENV) {
    case 'production':
        API_DOMAIN = 'pro-word-api.prod.ai-con-pro.com';
        LOGIN_DIALOG_DOMAIN = 'pro-word-add-in.prod.ai-con-pro.com';
        AUTH0_AUDIENCE = 'https://validate_access_token.ai-con-pro.prod.com';
        AUTH0_DOMAIN = 'ai-con-pro.auth0.com';
        AUTH0_CLIENT_ID = 'Jl84WhrSt00MMFbAUKX7ycaVauy22DlW';
        AUTH0_REDIRECT_URI = 'https://pro-word-add-in.prod.ai-con-pro.com/index.html#login_handler';
        INTERCOM_APP_ID = 'yite7r69';
        break;
    case 'staging':
        API_DOMAIN = 'pro-word-api.stag.ai-con-pro.com';
        LOGIN_DIALOG_DOMAIN = 'pro-word-add-in.stag.ai-con-pro.com';
        AUTH0_AUDIENCE = 'https://validate_access_token.ai-con-pro.stag.com';
        AUTH0_DOMAIN = 'ai-con-pro-staging.auth0.com';
        AUTH0_CLIENT_ID = 'KgHCwxs1A8BTt8niBIq7A9EyfqXdDpmL';
        AUTH0_REDIRECT_URI = 'https://pro-word-add-in.stag.ai-con-pro.com/index.html#login_handler';
        INTERCOM_APP_ID = 'x9nan0wp';  // test workspace.
        break;
    case 'develop':
        API_DOMAIN = 'pro-word-api.dev.ai-con-pro.com';
        LOGIN_DIALOG_DOMAIN = 'pro-word-add-in.dev.ai-con-pro.com';
        AUTH0_AUDIENCE = 'http://localhost:3030';
        AUTH0_DOMAIN = 'ai-con-pro-dev.auth0.com';
        AUTH0_CLIENT_ID = 'sQRfT19O2jqqFt7kVxdRSxhZT3S7iPJF';
        AUTH0_REDIRECT_URI = 'https://pro-word-add-in.dev.ai-con-pro.com/index.html#login_handler';
        INTERCOM_APP_ID = 'x9nan0wp';  // test workspace.
        break;
    case 'local':
        // Dockerにて完結。ESとRDSとAuth0は外部に接続にいきます。
        API_DOMAIN = 'www.localhost.com';
        LOGIN_DIALOG_DOMAIN = 'localhost:3000';
        AUTH0_AUDIENCE = '';
        AUTH0_DOMAIN = 'ai-con-pro-dev.auth0.com';
        AUTH0_CLIENT_ID = 'Bmu6OGwwFF3I5s3ZOxf4oUN6OD4lyVEB';
        AUTH0_REDIRECT_URI = 'https://localhost:3000/index.html#login_handler';
        INTERCOM_APP_ID = 'x9nan0wp';  // test workspace.
        break;
    case 'stand-alone':
        IS_STANDALONE = true;
        API_DOMAIN = 'www.stand-alone.com';  // for test.
        break;
    case 'test':
        IS_STANDALONE = true;
        IS_TEST = true;
        API_DOMAIN = 'www.stand-alone.com';  // for test.
        break;
}

