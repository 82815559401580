











import { Component, Emit } from 'vue-property-decorator';
import constants from '@/constants';
import App from '@/App.vue';

@Component
export default class Header extends App {
  private templateSearchType: number = this.$store.getters.templateSearchType;

  private templateSearchTypeGvaTech: number = constants.TEMPLATE_SEARCH_TYPE_GVATECH;
  private templateSearchTypeCustomers: number = constants.TEMPLATE_SEARCH_TYPE_CUSTOMER;

  @Emit()
  public changeTemplateSearchType(num: number): void {
      if (num === this.templateSearchTypeGvaTech) {
          this.templateSearchType = this.templateSearchTypeGvaTech;
      } else {
          this.templateSearchType = this.templateSearchTypeCustomers;
      }

      this.$store.commit('templateSearchType', num);
      this.$router.push({name: 'search-in-templates-page'});
  }
}
