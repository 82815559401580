var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "errorBox" }, [
    _c("figure", { staticClass: "errorImage" }),
    _vm._v(" "),
    _c("div", { staticClass: "errorTitleWrapper" }, [
      _c(
        "h2",
        {
          staticClass: "errorTitle",
          class: _vm.titleBrJudge ? "-searchInTemplate" : ""
        },
        [
          _vm._m(0),
          _vm._v(
            "\n            " + _vm._s(_vm.childErrorTitleText) + "\n        "
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "errorText" }, [_vm._v(_vm._s(_vm.errorText))])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "errorTitleImage" }, [
      _c("img", {
        attrs: { src: require("../../../assets/error_attention.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }