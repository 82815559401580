var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "templateMemoPage" } },
    [
      _c("Header", {
        attrs: { "template-search-type": _vm.templateSearchType },
        on: { "change-template-search-type": _vm.changeTemplateSearchType }
      }),
      _vm._v(" "),
      _c("main", { staticClass: "container" }, [
        _c("section", { staticClass: "backPage" }, [
          _c("div", { staticClass: "backPage__wrapper" }, [
            _c(
              "div",
              {
                staticClass: "backPage__button",
                on: {
                  click: function($event) {
                    return _vm.backWithQuery()
                  }
                }
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("span", { staticClass: "backPage__text" }, [
                  _vm._v(_vm._s(_vm.article.article_title))
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "memo" }, [
          _c("div", { staticClass: "memo__wrapper" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("p", { staticClass: "memo__text" }, [
              _vm._v(_vm._s(_vm.article.memo))
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("LogOutButton")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "backPage__arrow" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/back_arrow.png"),
          alt: "前のページへ戻ります"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "memo__Title" }, [
      _c("span", { staticClass: "if if-memo" }),
      _vm._v(" "),
      _c("span", { staticClass: "memo__title-text" }, [_vm._v("解説")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }