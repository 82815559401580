














































import { Component, Prop, Emit } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import ArticleCardMixin from '@/mixins/ArticleCardMixin.vue';

@Component
export default class DeleteArticleCard extends Mixins<ArticleCardMixin>(ArticleCardMixin) {

    // id : "d" + sort_order
    @Prop({default: ''})
    private readonly articleId!: string;

    @Prop({default: []})
    private readonly removeArticleIds!: string[];

    @Prop({default: {}})
    private readonly article!: object;

    @Emit()
    public changeParentRemoveArticleIds(articleId: string, articleIndex: number): string[] {
        return this.fixRemoveArticleIds(articleId, articleIndex, this.removeArticleIds);
    }
}
