









































import { Component, Prop, Emit } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import ArticleCardMixin from '@/mixins/ArticleCardMixin.vue';

@Component
export default class RequiredArticleCard extends Mixins<ArticleCardMixin>(ArticleCardMixin) {

    // id : "r" + sort_order
    @Prop({default: ''})
    private readonly articleId!: string;

    @Prop({default: []})
    private readonly removeArticleIds!: string[];

    @Prop({default: {}})
    private readonly article!: object;

    @Emit()
    public changeParentRemoveArticleIds(articleId: string): string[] {
        // not move paragraph
        const articleIndex: number = -1;
        return this.fixRemoveArticleIds(articleId, articleIndex, this.removeArticleIds);
    }

}
