var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header" }, [
    _c(
      "div",
      {
        staticClass: "header__item",
        on: {
          click: function($event) {
            return _vm.changeTemplateSearchType(0)
          }
        }
      },
      [
        _c(
          "span",
          {
            staticClass: "header__item-text",
            class: {
              "-active":
                _vm.templateSearchType === _vm.templateSearchTypeCustomers
            }
          },
          [_vm._v("自社ひな型")]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "header__item",
        on: {
          click: function($event) {
            return _vm.changeTemplateSearchType(1)
          }
        }
      },
      [
        _c(
          "span",
          {
            staticClass: "header__item-text",
            class: {
              "-active":
                _vm.templateSearchType === _vm.templateSearchTypeGvaTech
            }
          },
          [_vm._v("AI-CONひな型")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }