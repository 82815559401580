

















import { Component } from 'vue-property-decorator';
import App from '@/App.vue';

@Component
export default class Loading extends App {
}
