

















import { Component } from 'vue-property-decorator';
import Loading from '@molecules/Loading.vue';
import Header from '@organisms/Header.vue';
import SearchForm from '@organisms/SearchForm.vue';
import LogOutButton from '@molecules/LogOutButton.vue';
import axios from 'axios';
import constants from '@/constants';
import * as Config from '@/config';
import local from '@/constants/local';
import Auth from '@/Auth.vue';

@Component({
    components: {
        Loading,
        SearchForm,
        Header,
        LogOutButton,
    },
})

export default class SearchInTemplatesPage extends Auth {
    private showJudge?: boolean = true;
    private templateSearchType?: number = this.$store.getters.templateSearchType;
    private finishedLoading?: boolean = false;
    private contractTypes: any[] = [];
    private headers: object = {};

    public mounted() {
        this.changeTemplateSearchType(this.templateSearchType).then();
    }

    private async changeTemplateSearchType(num?: number): Promise<void> {
        await this.setContractTypesByFetchUrl(num);

        this.showJudge = false;
        this.$nextTick(() => (
            this.showJudge = true
        ));
    }

    // setContractTypesByFetchUrl sets contract types to data.
    private async setContractTypesByFetchUrl(num?: number): Promise<void> {
        if (Config.IS_STANDALONE) {
            this.contractTypes = local.DATA_CONTRACT_TYPES;
            this.finishedLoading = true;
        } else {
            const url: string = this.getEndpoint(num);

            try {
                const accessToken: any = await this.getAccessToken();
                this.headers = this.setAccessTokenToAuthorizationHeader({}, accessToken);
                const {data}: any = await axios.get(url, { headers: this.headers} );
                this.contractTypes = data;
                this.finishedLoading = true;
            } catch (err) {
                throw new Error(err);
            }
        }
    }

    // getEndpoint returns endpoint to get contract types.
    private getEndpoint(templateSearchType?: number): string {
        const searchType: string = this.getSearchTypes(templateSearchType);
        return `https://${Config.API_DOMAIN}/api/v1.0/${searchType}/contract_types`;
    }

}
