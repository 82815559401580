var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showJudge
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c("Loading", { attrs: { "finished-loading": _vm.finishedLoading } }),
          _vm._v(" "),
          _c("Header", {
            attrs: { "template-search-type": _vm.templateSearchType },
            on: { "change-template-search-type": _vm.changeTemplateSearchType }
          }),
          _vm._v(" "),
          _c("SearchForm", {
            attrs: { headers: _vm.headers, "contract-types": _vm.contractTypes }
          }),
          _vm._v(" "),
          _c("LogOutButton")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }