




import { Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import SearchTypeMixin from '@/mixins/SearchTypeMixin.vue';

@Component
export default class App extends Mixins<SearchTypeMixin>(SearchTypeMixin) {}
