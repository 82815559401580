


import { Component } from 'vue-property-decorator';
import Auth from '@/Auth.vue';

@Component
export default class LoginDialogPage extends Auth {
    public mounted() {
        this.loginHandler();
    }

    // ログインが成功したら「検索画面」にリダイレクトをさせる処理
    public async loginHandler(): Promise<void> {
        const auth0: any = this.$root.$data.auth0;
        // after auth0 login -> loginHandler -> LoginRedirect = SearchInTemplates
        await auth0.loginWithRedirect();
    }
}
